<template>
  <div class="table-container">
    <b-table
      :data="items"
      :loading="loading"
      striped
      hoverable
      bordered
      pagination-size="is-small"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
    >
      <template slot-scope="props">
        <b-table-column
          field="index"
          label="Index"
        >{{ sortOrder === 'asc' ? props.index  + 1 : total - props.index }}</b-table-column>

        <b-table-column field="id" label="Identifier" sortable>0x{{ ('00' + props.row.id.toString(16)).slice(-3) }}</b-table-column>
        <b-table-column field="vendor" label="Vendor" sortable>{{ props.row.vendor }}</b-table-column>
        <b-table-column field="name" label="Name" sortable>{{ props.row.name }}</b-table-column>
        <b-table-column field="description" label="Description" sortable>{{ props.row.description }}</b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'ProductFamily',
  data() {
    return {
      items: [],
      sortField: 'date',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      loading: false,
      total: 0,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      const res = await this.$http.get('api/v1/product/family');
      this.total = res.data.length;
      this.items = res.data;
      this.loading = false;
    },
  },
};
</script>
